import {createApp} from 'vue'
import {createPinia} from 'pinia'
import "core-js/stable";
import App from '@/unavailable/App.vue'
import Vue3Sanitize from "vue-3-sanitize";

import '@/shared/global';
import '@/unavailable/global';
import '@/unavailable/assets/scss/main.scss';
import sharedMixin from '@/shared/mixins';

const pinia = createPinia();
const app = createApp(App);
app.use(pinia);
app.use(Vue3Sanitize);
app.mixin(sharedMixin);
app.mount('#app');
