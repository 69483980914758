<template>
	<main>
		<div class="wrapper-banner-subscribe">
			<div class="container">
				<div class="col">
					<h1>{{ languageStore.getString("service_not_available") }}</h1>
					<p>{{ languageStore.getString("service_not_available_explain") }}</p>
				</div>
				<div class="wrapper-img centered" v-html="getIcon('imgBannerServiceUnavailable')"></div>
				<div class="col col-cta">
					<p>{{ languageStore.getString("business_owner_contact") }}</p>
					<AppButton :text="languageStore.getString('login')" @click="goToLogin"/>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import langObject from "@/unavailable/assets/json/langs.json";
import langObjectAuto from "@/unavailable/assets/json/auto.json";
import {useLanguageStore} from "@/shared/stores";
import {useSvgStore} from "@/shared/stores";
import API from "@/shared/helpers/API";
import {useMenusStore} from "@/new-menu/stores";
import {isTextColorDark} from "@/shared/helpers/ui";
import AppButton from "@/shared/components/AppButton.vue";

export default {
	components: {AppButton},
	setup() {
		const languageStore = useLanguageStore();
		const menusStore = useMenusStore();
		const svgStore = useSvgStore();
		return {languageStore, menusStore, getIcon: svgStore.get};
	},
	name: "App",
	data() {
		return {
			URL_HTML: URL_HTML,
			subdomain: subdomain,
		}
	},
	methods: {
		initStyle() {
			document.documentElement.style.setProperty('--font-family', this.menusStore.fontFamily);
			document.documentElement.style.setProperty('--theme-bg1-color', "#"+this.menusStore.theme.bg_1);
			document.documentElement.style.setProperty('--theme-bg1-alpha80-color', "#"+this.menusStore.theme.bg_1+"CC");
			document.documentElement.style.setProperty('--theme-bg1-alpha40-color', "#"+this.menusStore.theme.bg_1+"64");
			document.documentElement.style.setProperty('--theme-bg2-color', "#"+this.menusStore.theme.bg_2);
			document.documentElement.style.setProperty('--theme-bg2-alpha40-color', "#"+this.menusStore.theme.bg_2+"64");
			document.documentElement.style.setProperty('--theme-txt1-color', "#"+this.menusStore.theme.txt_1);
			document.documentElement.style.setProperty('--theme-txt1-alpha20-color', "#"+this.menusStore.theme.txt_1+"32");
			document.documentElement.style.setProperty('--theme-txt1-alpha40-color', "#"+this.menusStore.theme.txt_1+"64");
			document.documentElement.style.setProperty('--theme-txt1-alpha60-color', "#"+this.menusStore.theme.txt_1+"96");
			document.documentElement.style.setProperty('--theme-txt1-alpha80-color', "#"+this.menusStore.theme.txt_1+"CC");
			document.documentElement.style.setProperty('--theme-txt1-alpha0-color', "#"+this.menusStore.theme.txt_1+"00");
			document.documentElement.style.setProperty('--theme-txt2-color', "#"+this.menusStore.theme.txt_2);
			document.documentElement.style.setProperty('--theme-txt2-alpha60-color', "#"+this.menusStore.theme.txt_2+"96");
			document.documentElement.style.setProperty('--business-color', "#"+this.menusStore.business.color);
			document.documentElement.style.setProperty('--business-color-alpha20', "#"+this.menusStore.business.color+"32");
			document.documentElement.style.setProperty('--business-color-alpha40', "#"+this.menusStore.business.color+"64");
			document.documentElement.style.setProperty('--business-color-alpha60', "#"+this.menusStore.business.color+"96");
			document.documentElement.style.setProperty('--business-color-alpha80', "#"+this.menusStore.business.color+"CC");
			document.documentElement.style.setProperty('--business-text-color', isTextColorDark(this.menusStore.business.color) ? "#000000" : "#FFFFFF");
			document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#' + this.menusStore.theme.bg_1);
		},
		goToLogin() {
			window.location.replace(URL_HTML+"/console/new/plugins/marketplace/");
		}
	},
	mounted() {
		this.initStyle();
	},
	created() {
		this.languageStore.addStrings(langObject, "unavailable", langObjectAuto);
		let protocol = ((TEST && LOCAL) ? "http://" : "https://");
		API.init(protocol+window.location.host, URL_HTML+"/console/login/");

		document.addEventListener('dblclick', function(event) {
			event.preventDefault();
		}, { passive: false });

	},
}
</script>
<style lang="scss">
div, span, p, h1, h2, h3, h4, h5, h6, a, button, label, select {
	@include noSelect();
	font-family: var(--font-family) !important;
}
html, body {
	@include hideScrollbar();
}
p, span, h1, input, textarea, select, option, li, label, a, b, i, [type="button"], [type="reset"], [type="submit"] {

	&::selection {
		background: var(--business-color-alpha20);
	}

	&::-moz-selection {
		background: var(--business-color-alpha20);
	}

	&::placeholder {
		font-family: var(--font-family) !important;
	}

}
input, select, textarea, button {
	font-family: var(--font-family) !important;
}
</style>
<style lang="scss" scoped>
main {
	.wrapper-banner-subscribe {
		background-color: var(--theme-bg1-color);
		.container {
			min-height: 100vh;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 30px;
			padding: 40px 20px;
			.col {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 5px;
				&-cta {
					background-color: var(--theme-bg2-color);
					gap: 15px;
					padding: 30px;
					border-radius: 26px;
					width: 100%;
					max-width: 500px;
				}
				h1 {
					color: var(--theme-txt1-color);
					font-size: 30px;
					line-height: 39px;
					text-align: center;
					font-style: unset;
					@media (max-width: 470px){
						font-size: 26px;
						line-height: 34px;
					}
				}
				p {
					color: var(--theme-txt2-color);
					font-size: 16px;
					line-height: 25px;
					text-align: center;
				}
			}
			.wrapper-img {
				background-color: var(--theme-bg2-color);
				padding: 20px;
				border-radius: 26px;
				max-width: 500px;
				width: 100%;
				flex: 0 0 100%;
				height: 500px;
				align-items: unset;
			}
			:deep(.dynamic-color) {
				.cls-1 {
					fill: var(--business-color);
				}
				.cls-2 {
					fill: var(--theme-txt2-color);
				}
				.cls-3 {
					stroke: var(--theme-bg1-color);
				}
				.cls-4 {
					stroke: var(--theme-txt1-color);
				}
				.cls-5 {
					stroke: var(--business-color);
				}
				.cls-6 {
					fill: var(--theme-bg2-color);
				}
				.cls-8 {
					stroke: var(--theme-txt2-color);
				}
			}
			:deep(.base-button) {
				background-color: var(--business-color);
				height: 44px;
				min-width: 150px;
				.btn-text {
					P {
						color: var(--theme-bg2-color);
					}
				}
			}
		}
	}
}
</style>